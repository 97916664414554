import React from 'react';
import ReadingMultipleChoice from './ReadingMultipleChoice';
import ReadingTrueFalseNotGiven from './ReadingTrueFalseNotGiven';
import ListeningMultipleChoice from './ListeningMultipleChoice';
import WritingEssay from './WritingEssay';
import WritingLetter from './WritingLetter';
import ListeningFillInTheBlanks from './ListeningFillInTheBlanks';
import SpeakingMonologue from './SpeakingMonologue';
import SpeakingPictureDescription from './SpeakingPictureDescription';
import ReadingClozeText from './ReadingClozeText';
import WritingTableDescription from './WritingTableDescription';
import ListeningTrueFalseNotGiven from './ListeningTrueFalseNotGiven';
import SpeakingArgumentation from './SpeakingArgumentation';
import ReadingMatchingHeadings from './ReadingMatchingHeadings';
import WritingTextSummarisation from './WritingTextSummarisation';
import ListeningMatchingSpeakers from './ListeningMatchingSpeakers';
import SpeakingCompareContrast from './SpeakingCompareContrast';

const exercises = {
    "reading/multiple_choice":          { iconClass: 'bi bi-book', title: 'Multiple Choice',                component: ReadingMultipleChoice,       api: '/api/exercises/reading/multiple_choice' },
    "reading/true_false_not_given":     { iconClass: 'bi bi-book', title: 'True / False / Not Given',       component: ReadingTrueFalseNotGiven,    api: '/api/exercises/reading/true_false_not_given' },
    "reading/cloze_text":               { iconClass: 'bi bi-book', title: 'Cloze Text',                     component: ReadingClozeText,            api: '/api/exercises/reading/cloze_text' },
    "reading/matching_headings":        { iconClass: 'bi bi-book', title: 'Matching Headings',              component: ReadingMatchingHeadings,     api: '/api/exercises/reading/matching_headings' },
    "writing/essay":                    { iconClass: 'bi bi-pencil', title: 'Essay',                        component: WritingEssay,                api: '/api/exercises/writing/essay' },
    "writing/letter":                   { iconClass: 'bi bi-pencil', title: 'Letter',                       component: WritingLetter,               api: '/api/exercises/writing/letter' },
    "writing/table_description":        { iconClass: 'bi bi-pencil', title: 'Table Description',            component: WritingTableDescription,     api: '/api/exercises/writing/table_description' },
    "writing/text_summarisation":       { iconClass: 'bi bi-pencil', title: 'Text Summarisation',           component: WritingTextSummarisation,    api: '/api/exercises/writing/text_summarisation' },
    "listening/multiple_choice":        { iconClass: 'bi bi-headphones', title: 'Multiple Choice',          component: ListeningMultipleChoice,     api: '/api/exercises/listening/multiple_choice' },
    "listening/fill_in_the_blanks":     { iconClass: 'bi bi-headphones', title: 'Fill in the Blanks',       component: ListeningFillInTheBlanks,    api: '/api/exercises/listening/fill_in_the_blanks' },
    "listening/true_false_not_given":   { iconClass: 'bi bi-headphones', title: 'True / False / Not Given', component: ListeningTrueFalseNotGiven,  api: '/api/exercises/listening/true_false_not_given' },
    "listening/matching_speakers":      { iconClass: 'bi bi-headphones', title: 'Matching Speakers',        component: ListeningMatchingSpeakers,   api: '/api/exercises/listening/matching_speakers' },
    "speaking/monologue":               { iconClass: 'bi bi-mic', title: 'Monologue',                       component: SpeakingMonologue,           api: '/api/exercises/speaking/monologue' },
    "speaking/picture_description":     { iconClass: 'bi bi-mic', title: 'Picture Description',             component: SpeakingPictureDescription,  api: '/api/exercises/speaking/picture_description' },
    "speaking/argumentation":           { iconClass: 'bi bi-mic', title: 'Argumentation',                   component: SpeakingArgumentation,       api: '/api/exercises/speaking/argumentation' },
    "speaking/compare_contrast":        { iconClass: 'bi bi-mic', title: 'Compare and Contrast',            component: SpeakingCompareContrast,     api: '/api/exercises/speaking/compare_contrast' },
};

const Exercise = ({ id }) => {
    const { iconClass, title, component: Component, api } = exercises[id];
    return (
        <div className="container mt-4 col-xxl-8">
            <h1 className="mb-4 text-center" style={{ color: "#343a40" }}>
                <i className={iconClass}></i> {title}
            </h1>
            <Component api={api} />
        </div>
    );
};

export default Exercise;
