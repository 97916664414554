import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import styles from "../styles/FeedbackButton.module.css"; // Importing the CSS module

const FeedbackButton = () => {
  const { user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState(null); // For displaying success/error messages

  const openModal = () => {
    setIsModalOpen(true);
    setFeedbackStatus(null); // Reset status when opening modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (feedbackStatus === "success") {
      setFeedbackText(""); // Reset feedback text only after successful submission
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const submitFeedback = async () => {
    if (feedbackText.trim() === "") {
      setFeedbackStatus("error");
      return;
    }

    try {
      const pageUrl = window.location.href; // Get the current page URL
      const userEmail = user?.email || null;

      const response = await axios.post('/api/feedback/submit-feedback', {
        feedbackText,
        userEmail,
        pageUrl
      });

      if (response.status === 201) {
        setFeedbackStatus("success");
      } else {
        setFeedbackStatus("error");
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setFeedbackStatus("error");
    }
  };

  return (
    <>
      <button className={`btn btn-primary ${styles.feedbackButton}`} onClick={openModal}>
        <i className="bi bi-chat-dots"></i> Feedback
      </button>

      {isModalOpen && (
        <div className={`modal fade show d-block ${styles.modalBackdrop}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Submit Feedback</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {feedbackStatus !== "success" ? (
                  <textarea
                    className="form-control"
                    value={feedbackText}
                    onChange={handleFeedbackChange}
                    placeholder="Please share your feedback here..."
                    rows="5"
                  ></textarea>
                ) : (
                  <div className="alert alert-success m-0" role="alert">
                    Thank you for your feedback!
                  </div>
                )}
                {feedbackStatus === "error" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    Please enter your feedback before submitting.
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Close
                </button>
                {feedbackStatus !== "success" && (
                  <button type="button" className="btn btn-primary" onClick={submitFeedback}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackButton;
