import React from "react";

const BlankItem = ({ index, question, userAnswer, result, onInputChange, onSubmit, onHover, onLeave }) => {
  const renderSentenceWithBlanks = () => {
    const parts = question.sentence.split("_____");

    return (
      <div className="mb-3 d-flex align-items-center" style={{ fontSize: "1.25rem" }}>
        <span>{index + 1}.&nbsp;</span>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            <span>{part}</span>
            {i < parts.length - 1 && (
              result ? (
                <div className="btn-group d-inline-block mx-1" role="group">
                  <button
                    className={`btn ${result.isCorrect ? "btn-success" : "btn-danger"}`}
                    disabled
                  >
                    {result.userAnswer}
                  </button>
                  {!result.isCorrect && (
                    <button className="btn btn-success" disabled>
                      {result.correctAnswer}
                    </button>
                  )}
                </div>
              ) : (
                <input
                  type="text"
                  className="form-control d-inline-block mx-1"
                  style={{ width: "150px", fontSize: "1.25rem" }}
                  onChange={(e) => onInputChange(index, e.target.value)}
                  value={userAnswer || ""}
                />
              )
            )}
          </React.Fragment>
        ))}
        {!result && (
          <button className="btn btn-primary mx-2" onClick={() => onSubmit(index)}>
            Submit
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="question-item mb-4" onMouseEnter={onHover} onMouseLeave={onLeave}>
      {renderSentenceWithBlanks()}
      {result && (
        <div className="alert alert-warning mt-2" role="alert">
          {result.explanation || "No explanation provided."}
        </div>
      )}
    </div>
  );
};

export default BlankItem;
