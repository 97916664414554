import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import QuestionsList from "../components/QuestionsList";
import FeedbackMessage from "../components/FeedbackMessage";
import Transcript from "../components/Transcript";
import AudioPlayer from "../components/AudioPlayer";
import ExerciseScorePlot from '../components/ExerciseScorePlot';

const ListeningMultipleChoice = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore( data._id, calculatedScore); }
  const handleTryAgain = () => { setScore(null); setResetTrigger(prev => !prev); setHighlight(""); };
  const handleNextExercise = () => { refetchData(); handleTryAgain(); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <AudioPlayer src={data.content.audioUrl} />
      <Transcript conversation={data.content.conversation} highlight={highlight} />
      <QuestionsList
        questions={data.content.questions}
        onHighlightChange={setHighlight}
        onComplete={handleCompletion}
        resetTrigger={resetTrigger}
      />
      {score !== null && <>
        <FeedbackMessage score={score} />
        <ExerciseScorePlot data={data} />
        <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
      </>}
    </div>
  );
};

export default ListeningMultipleChoice;
