import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from '../styles/LoginPage.module.css'; // Import custom CSS module
import logo from '../assets/logo.png'; // Import your logo
import axios from 'axios';

const LoginPage = () => {
  const { login, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      const verifyEmail = async () => {
        try {
          const response = await axios.get(`/api/auth/verify-email?token=${token}`);
          setStatus('Email verified successfully. Please log in.');
        } catch (error) {
          setStatus(error.response?.data?.message || 'Failed to verify email. Please try again.');
        }
      };
      verifyEmail();
    }
  }, [user, navigate, location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      setError(error.response?.data?.message || 'Invalid credentials. Please try again.');
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signin w-100 m-auto ${styles.formSignin}`}>
        
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="100" />
          <h1 className="h3 mb-3 fw-normal">Log in</h1>

          {status && (
            <div className="alert alert-success" role="alert">
              {status}
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <button className="btn btn-primary w-100 py-2" type="submit">Sign in</button>
          <p className="mt-5 mb-3 text-body-secondary">&copy; 2024 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default LoginPage;
