import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import ClozePassage from "../components/ClozePassage";
import ExerciseScorePlot from "../components/ExerciseScorePlot";

const ReadingClozeTest = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore( data._id, calculatedScore); }
  const handleTryAgain = () => { setScore(null); setResetTrigger((prev) => !prev); };
  const handleNextExercise = () => { refetchData(); handleTryAgain(); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <ClozePassage
        passage={data.content.passage}
        answers={data.content.answers}
        onComplete={handleCompletion}
        resetTrigger={resetTrigger}
      />
      {score !== null && (
        <>
          <FeedbackMessage score={score} />
          <ExerciseScorePlot data={data} />
          <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
        </>
      )}
    </div>
  );
};

export default ReadingClozeTest;
