import React from "react";

const Explanations = ({ explanations }) => {
  return (
    <div className="alert alert-warning mt-4">
      <h2 className="text-center">Explanations</h2>
      {Object.keys(explanations).map((key, index) => (
        <div key={index} style={{ marginBottom: "15px" }}> {/* Add vertical spacing */}
          <strong>({parseInt(key) + 1})</strong>{" "}
          {explanations[key].explanation}
        </div>
      ))}
    </div>
  );
};

export default Explanations;
