import React from "react";

const QuestionItem = ({ questionData, questionIndex, userAnswer, onOptionChange, onHover, onLeave }) => {
  const handleOptionClick = (option) => {
    const isCorrect = option === questionData.answer;
    onOptionChange(questionIndex, { userAnswer: option, isCorrect });
  };

  return (
    <div className="question-item mb-4" onMouseEnter={onHover} onMouseLeave={onLeave}>
      <div className="mb-3" style={{ fontSize: "1.25rem" }}>
        {questionIndex + 1}. {questionData.question}
        <div className="btn-group ms-3" role="group">
          {questionData.options.map((option, optIndex) => {
            let optionClass = "btn btn-outline-primary";
            if (userAnswer !== undefined) {
              if (option === questionData.answer) optionClass = "btn btn-success";
              else if (option === userAnswer.userAnswer) optionClass = "btn btn-danger";
            }
            return (
              <button
                key={optIndex}
                className={optionClass}
                onClick={() => handleOptionClick(option)}
                disabled={userAnswer !== undefined}
              >
                {option}
              </button>
            );
          })}
        </div>
      </div>
      {userAnswer && (
        <div className="alert alert-warning mt-2" role="alert">
          {questionData.explanation || "No explanation provided."}
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
