import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import styles from '../styles/ReadingPage.module.css';

const exercises = [
  { name: 'Multiple Choice', path: '/reading/multiple_choice', icon: 'bi-list-check' },
  { name: 'True / False / Not Given', path: '/reading/true_false_not_given', icon: 'bi-check-circle' },
  { name: 'Cloze Text', path: '/reading/cloze_text', icon: 'bi-pencil-square' },
  { name: 'Matching Headings', path: '/reading/matching_headings', icon: 'bi-text-paragraph' },
];

const ReadingPage = () => {
  return (
    <div className="container mt-5 col-xxl-8">
      <Breadcrumb />
      <ul className="list-group">
        {exercises.map((exercise, index) => (
          <li key={index} className="list-group-item fs-5">
            <Link to={exercise.path} className={`${styles.listItemLink} d-flex align-items-center`}>
              <i className={`bi ${exercise.icon} me-2`}></i>
            {index + 1}. {exercise.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReadingPage;
