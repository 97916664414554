import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import styles from '../styles/SpeakingPage.module.css';

const exercises = [
  { name: 'Monologue', path: '/speaking/monologue', icon: 'bi-chat-dots' },
  { name: 'Picture Description', path: '/speaking/picture_description', icon: 'bi-image' },
  { name: 'Argumentation', path: '/speaking/argumentation', icon: 'bi-chat-right-quote' },
  { name: 'Compare and Contrast', path: '/speaking/compare_contrast', icon: 'bi-arrows-expand-vertical' }
];

const SpeakingPage = () => {
  return (
    <div className="container mt-5 col-xxl-8">
      <Breadcrumb />
      <ul className="list-group">
        {exercises.map((exercise, index) => (
          <li key={index} className="list-group-item fs-5">
            <Link to={exercise.path} className={`${styles.listItemLink} d-flex align-items-center`}>
              <i className={`bi ${exercise.icon} me-2`}></i>
            {index + 1}. {exercise.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpeakingPage;
