import React from 'react';

const AudioPlayer = ({ src }) => {
  if (!src) return null;

  return (
    <audio controls src={src} className="mb-4">
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioPlayer;
