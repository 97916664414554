import React from 'react';
import ScorePlot from './ScorePlot'; // Assuming you have the ScorePlot component

const ExerciseScorePlot = ({ data }) => {
  if (!data) return null; // Handle cases where data is not available

  return (
    <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
      <ScorePlot 
        exerciseCategory={data.section} 
        exerciseType={data.exercise}
        knownLanguage={data.knownLanguage}
        targetLanguage={data.targetLanguage}
        proficiencyLevel={data.proficiencyLevel}
      />
    </div>
  );
};

export default ExerciseScorePlot;
