import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ExerciseRecommendation = () => {
  const [recommendation, setRecommendation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false); // Track if we're refreshing

  const fetchRecommendation = async () => {
    try {
      const token = localStorage.getItem('token'); // Assumes JWT is stored in localStorage
      const response = await axios.get('/api/performance/exercise-recommendation', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setRecommendation(response.data);
      setLoading(false);
      setRefreshing(false); // Reset the refreshing state after fetching
    } catch (err) {
      setError('Error fetching exercise recommendation');
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchRecommendation();
  }, []);

  const handleRefresh = () => {
    setRefreshing(true); // Mark as refreshing
    setLoading(true); // Trigger loading state
    fetchRecommendation(); // Fetch a new recommendation
  };

  // Function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split string by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words back together with spaces
  };

  // Capitalize the category and type
  const readableCategory = recommendation ? capitalizeWords(recommendation.category) : '';
  const readableType = recommendation ? capitalizeWords(recommendation.type) : '';

  // Construct the link path
  const linkPath = recommendation ? `/${recommendation.category}/${recommendation.type}` : '#';

  // Generate a friendly message based on the reason and score
  let reasonMessage = '';
  if (recommendation && recommendation.reason === 'unattempted') {
    reasonMessage = 'because you have not attempted this exercise yet.';
  } else if (recommendation && recommendation.reason === 'weak') {
    reasonMessage = `because you recently scored ${recommendation.score}% in it.`;
  }

  return (
    <div className='alert alert-warning text-center d-flex flex-wrap align-items-center justify-content-center'>
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      
      {!loading && !error && recommendation && (
        <>
          <i className='bi-magic' style={{ marginRight: '8px' }}></i>
          <span>Suggested exercise:&nbsp;</span>
          <Link to={linkPath} className="text-break">{`${readableCategory} / ${readableType}`}</Link>,&nbsp;
          <span className="text-break">{reasonMessage}</span>

          {/* Inline refresh icon */}
          <i
            className={`bi-arrow-clockwise ms-2 ${refreshing ? 'text-muted' : 'text-primary'}`}
            style={{ fontSize: '1.2rem', cursor: refreshing ? 'not-allowed' : 'pointer', verticalAlign: 'middle' }}
            onClick={!refreshing ? handleRefresh : null} // Disable if refreshing
            title="Refresh Recommendation"
          />
        </>
      )}
    </div>
  );
};

export default ExerciseRecommendation;
