import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ProfilePage from './pages/ProfilePage';
import ReadingPage from './pages/ReadingPage';
import WritingPage from './pages/WritingPage';
import ListeningPage from './pages/ListeningPage';
import SpeakingPage from './pages/SpeakingPage';
import SettingsPage from './pages/SettingsPage';
import DashboardPage from './pages/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import VerifyEmailPage from './pages/VerifyEmailPage';
import UpgradePage from './pages/UpgradePage';
import PremiumRoute from './components/PremiumRoute';
import PremiumPage from './pages/PremiumPage';
import Exercise from './exercises/Exercise';
import FeedbackButton from './components/FeedbackButton';
import './styles/App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<><LoginPage /><FeedbackButton /></>} />
          <Route path="/signup" element={<><SignupPage /><FeedbackButton /></>} />
          <Route path="/verify-email" element={<><VerifyEmailPage /><FeedbackButton /></>} />
          <Route path="/upgrade" element={<><UpgradePage /><FeedbackButton /></>} />
          <Route path="/*" element={<MainLayout />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => (
  <div className="d-flex flex-column min-vh-100">
    <Header />
    <div className="flex-grow-1">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
        <Route path="/premium" element={<PrivateRoute><PremiumPage /></PrivateRoute>} />
        <Route path="/reading" element={<PrivateRoute><ReadingPage /></PrivateRoute>} />
        <Route path="/writing" element={<PrivateRoute><WritingPage /></PrivateRoute>} />
        <Route path="/listening" element={<PrivateRoute><ListeningPage /></PrivateRoute>} />
        <Route path="/speaking" element={<PrivateRoute><SpeakingPage /></PrivateRoute>} />
        <Route path="/reading/multiple_choice" element={<PrivateRoute><Exercise id="reading/multiple_choice" /></PrivateRoute>} />
        <Route path="/reading/true_false_not_given" element={<PrivateRoute><Exercise id="reading/true_false_not_given" /></PrivateRoute>} />
        <Route path="/reading/cloze_text" element={<PrivateRoute><Exercise id="reading/cloze_text" /></PrivateRoute>} />
        <Route path="/reading/yes_no_not_given" element={<PrivateRoute><Exercise id="reading/yes_no_not_given" /></PrivateRoute>} />
        <Route path="/reading/matching_headings" element={<PrivateRoute><Exercise id="reading/matching_headings" /></PrivateRoute>} />
        <Route path="/writing/essay" element={<PrivateRoute><Exercise id="writing/essay" /></PrivateRoute>} />
        <Route path="/writing/letter" element={<PrivateRoute><Exercise id="writing/letter" /></PrivateRoute>} />
        <Route path="/writing/table_description" element={<PrivateRoute><Exercise id="writing/table_description" /></PrivateRoute>} />
        <Route path="/writing/text_summarisation" element={<PrivateRoute><Exercise id="writing/text_summarisation" /></PrivateRoute>} />
        <Route path="/listening/multiple_choice" element={<PrivateRoute><Exercise id="listening/multiple_choice" /></PrivateRoute>} />
        <Route path="/listening/fill_in_the_blanks" element={<PrivateRoute><Exercise id="listening/fill_in_the_blanks" /></PrivateRoute>} />
        <Route path="/listening/true_false_not_given" element={<PrivateRoute><Exercise id="listening/true_false_not_given" /></PrivateRoute>} />
        <Route path="/listening/matching_speakers" element={<PrivateRoute><Exercise id="listening/matching_speakers" /></PrivateRoute>} />
        <Route path="/speaking/monologue" element={<PrivateRoute><Exercise id="speaking/monologue" /></PrivateRoute>} />
        <Route path="/speaking/picture_description" element={<PrivateRoute><Exercise id="speaking/picture_description" /></PrivateRoute>} />
        <Route path="/speaking/argumentation" element={<PrivateRoute><Exercise id="speaking/argumentation" /></PrivateRoute>} />
        <Route path="/speaking/compare_contrast" element={<PrivateRoute><Exercise id="speaking/compare_contrast" /></PrivateRoute>} />
      </Routes>
    </div>
    <Footer />
    <FeedbackButton />
  </div>
);

export default App;
