import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitExercise } from "../hooks/useSubmitExercise";
import WritingArea from "../components/WritingArea";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackWriting from "../components/FeedbackWriting.js";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import Table from "../components/Table";
import ExerciseScorePlot from "../components/ExerciseScorePlot.js";

const WritingTableDescription = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { result, setResult, isSubmitting, submitExercise } = useSubmitExercise();
  const [essayText, setEssayText] = useState('');

  const handleEssayChange = (text) => { setEssayText(text); };

  const handleSubmit = () => {
    submitExercise(api, { 
      'exerciseId': data._id,
      'table': data.content.table,
      'response': essayText
    })
    .then((response) => { setEssayText(response.correctedResponse); });
  };

  const handleTryAgain = () => { setEssayText(''); setResult(null); };
  const handleNextExercise = () => { refetchData(); setEssayText(''); setResult(null); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <div className="alert alert-light mb-4">
        <p>{data.content.instructions}</p>
        <Table tableData={data.content.table} />
      </div>

      <WritingArea value={essayText} onChange={handleEssayChange} />

      <div className="d-flex align-items-center">
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>Submit</button>
        {isSubmitting && <LoadingSpinner inline={true} />}
      </div>

      {result && <>
        <FeedbackWriting feedback={result} />
        <FeedbackMessage score={result.generalFeedbackAndOverallScore.score / 10} />
        <ExerciseScorePlot data={data} />
        <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
      </>}
    </div>
  );
};

export default WritingTableDescription;
