import React from "react";

const HeadingItem = ({ paragraphLabel, section, headings, selected, onChange, onHoverChange, showResults }) => {
  const handleOptionClick = (option) => {
    onChange(option);
  };

  return (
    <div className="mb-4">
      <div
        className="d-flex align-items-center mb-3"
        onMouseEnter={() => onHoverChange(section.reference)} // Highlight when hovering
        onMouseLeave={() => onHoverChange("")} // Clear highlight when not hovering
      >
        <h5 className="me-3">{paragraphLabel}.</h5>

        {/* Before submission: Render a select dropdown */}
        {!showResults ? (
          <select
            value={selected}
            onChange={(e) => handleOptionClick(e.target.value)}
            className="form-select w-auto"
          >
            <option value="" disabled>
              Select a heading
            </option>
            {headings.map((heading, index) => (
              <option key={index} value={heading}>
                {heading}
              </option>
            ))}
          </select>
        ) : (
          <div className="btn-group" role="group">
            {/* Show the correct button in green */}
            <button className="btn btn-success" disabled>
              {section.heading}
            </button>

            {/* Show the incorrect button (if applicable) on the left */}
            {selected !== section.heading && (
              <button className="btn btn-danger" disabled>
                {selected}
              </button>
            )}
          </div>
        )}
      </div>

      {showResults && (
        <div
          className="mt-2 p-2 alert alert-warning"
          onMouseEnter={() => onHoverChange(section.reference)} // Highlight when hovering over explanation
          onMouseLeave={() => onHoverChange("")} // Clear highlight when not hovering
        >
          {section.explanation}
        </div>
      )}
    </div>
  );
};

export default HeadingItem;
