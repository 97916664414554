import React from "react";
import highlightExamples from '../utils/highlightExamples.js';

const SuggestionsList = ({ suggestions }) => (
  <ul className="ps-3">
    {suggestions.map((suggestion, i) => (
      <li key={i}>{highlightExamples(suggestion)}</li>
    ))}
  </ul>
);

export default SuggestionsList;
