import React, { useState, useEffect } from "react";
import HeadingItem from "./HeadingItem";

// Utility function to shuffle the headings array
const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const areAllHeadingsSelected = (answers, passage) => {
  return passage.every((section) => answers[section.text]);
};

const HeadingsList = ({ passage, headings, onHighlightChange, onComplete, resetTrigger, showResults }) => {
  const [answers, setAnswers] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [shuffledHeadings, setShuffledHeadings] = useState([]);

  // Shuffle the headings once when component is mounted or resetTrigger changes
  useEffect(() => {
    setShuffledHeadings(shuffleArray(headings));
    setAnswers({});
    setSubmitDisabled(true);
  }, [resetTrigger, headings]);

  useEffect(() => {
    if (areAllHeadingsSelected(answers, passage)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [answers, passage]);

  const handleAnswerChange = (paragraphText, selectedHeading) => {
    setAnswers({ ...answers, [paragraphText]: selectedHeading });
  };

  const handleHoverChange = (reference) => {
    if (showResults && reference) {
      onHighlightChange(reference); // Highlight the text reference
    } else {
      onHighlightChange(""); // Clear the highlight when not hovering
    }
  };

  const handleCompletion = () => {
    const score = passage.filter(section => answers[section.text] === section.heading).length / passage.length;
    onComplete(score);
  };

  return (
    <div>
      {passage.map((section, index) => (
        <HeadingItem
          key={index}
          paragraphLabel={String.fromCharCode(65 + index)} // A, B, C...
          section={section}
          headings={shuffledHeadings} // Use shuffled headings
          selected={answers[section.text] || ""}
          onChange={(selected) => handleAnswerChange(section.text, selected)}
          onHoverChange={(ref) => handleHoverChange(ref)} // Handle hovering
          showResults={showResults}
        />
      ))}

      {/* Submit Button */}
      {!showResults && (
        <button onClick={handleCompletion} disabled={isSubmitDisabled} className="btn btn-primary">
          Submit
        </button>
      )}
    </div>
  );
};

export default HeadingsList;
