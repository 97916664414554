import React from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitExercise } from "../hooks/useSubmitExercise";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackSpeaking from "../components/FeedbackSpeaking";
import AudioRecorder from "../components/AudioRecorder";
import CorrectedResponse from "../components/CorrectedResponse";
import ExerciseNavigation from "../components/ExerciseNavigation";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseScorePlot from "../components/ExerciseScorePlot";

const SpeakingPictureDescription = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { result, setResult, isSubmitting, submitExercise } = useSubmitExercise();

  const handleStopRecording = (blob) => {
    const formData = new FormData();
    formData.append("exerciseId", data._id);
    formData.append("prompt", data.content.prompt);
    formData.append("audio", blob, `audio.${blob.type.split("/")[1]}`);
    submitExercise(`${api}`, formData, 'multipart/form-data');
  };

  const handleRetry = () => { setResult(null); };
  const handleNext = () => { setResult(null); refetchData();};

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <div className="mb-3 alert alert-light">
        <div className="mb-3">{data.content.prompt}</div>
        {!result &&
          <div className="d-flex">
            <AudioRecorder onStopRecording={handleStopRecording} disabled={isSubmitting} />
            {isSubmitting && <LoadingSpinner inline={true} />}
          </div>
        }
      </div>

      {result && <>
        <CorrectedResponse text={result.correctedTranscription} />
        <FeedbackSpeaking feedback={result} />
        <FeedbackMessage score={result.generalFeedbackAndOverallScore.score / 10} />
        <ExerciseScorePlot data={data} />
        <ExerciseNavigation onRetry={handleRetry} onNext={handleNext} />
      </>}
    </div>
  );
};

export default SpeakingPictureDescription;
