import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ScorePlot = ({ 
  title,
  exerciseCategory, 
  exerciseType, 
  knownLanguage, 
  targetLanguage, 
  proficiencyLevel 
}) => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPerformance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/exercises/user-performance', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch performance data');
        }

        const data = await response.json();

        // Filter scores based on the provided props (if specified)
        const filteredScores = data.filter(score => {
          let isMatch = true;
          if (exerciseCategory && score.exerciseCategory !== exerciseCategory) isMatch = false;
          if (exerciseType && score.exerciseType !== exerciseType) isMatch = false;
          if (knownLanguage && score.knownLanguage !== knownLanguage) isMatch = false;
          if (targetLanguage && score.targetLanguage !== targetLanguage) isMatch = false;
          if (proficiencyLevel && score.proficiencyLevel !== proficiencyLevel) isMatch = false;
          return isMatch;
        });

        setScores(filteredScores);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPerformance();
  }, [exerciseCategory, exerciseType, knownLanguage, targetLanguage, proficiencyLevel]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  const chartData = {
    labels: scores.map((score) =>
      new Date(score.createdAt).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit'
      })
    ), // Dates formatted as dd/mm
    datasets: [
      {
        label: '', // No need for a dataset label
        data: scores.map((score) => score.score * 100), // Assuming score is in decimal
        fill: false,
        borderColor: '#0d6efd', // Line color changed to Bootstrap's primary blue
        tension: 0.1
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false // Disable the legend
      },
      title: {
        display: true, // Enable the chart title
        text: title || 'Performance Over Time',
        font: {
          size: 16,
          weight: 'normal'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.raw + '%'; // Show percentage on hover
          }
        }
      }
    },
    scales: {
      x: {
        type: 'category',
        title: { display: false } // Optionally hide x-axis title
      },
      y: {
        title: { display: false }, // Optionally hide y-axis title
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          }
        }
      }
    },
    aspectRatio: 1 // Keep default aspect ratio
  };

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ScorePlot;
