import React, { useContext, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import SignUpNow from '../components/SignUpNow';

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);
  const location = useLocation();

  // Redirect to /profile if the user is already logged in
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  // Scroll to sections based on hash in URL
  useEffect(() => {
    if (location.hash === '#features') {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#pricing') {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#faq') {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <Hero />
      <div ref={featuresRef}><Features /></div>
      <div ref={pricingRef}><Pricing /></div>
      <div ref={faqRef}><FAQ /></div>
      <div><SignUpNow /></div>
    </>
  );
};

export default LandingPage;
