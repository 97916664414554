import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from '../styles/SignupPage.module.css'; // Import custom CSS module
import logo from '../assets/logo.png'; // Import your logo

const SignupPage = () => {
  const { user, register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await register(name, email, password);
      setStatusMessage(data.message || 'Registration successful. Please check your email (including your Spam folder) to verify your account.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Failed to register. Please try again.');
      setStatusMessage('');
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signup w-100 m-auto ${styles.formSignup}`}>
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="100" />
          <h1 className="h3 mb-3 fw-normal">Sign up</h1>

          {statusMessage && <div className="alert alert-success" role="alert">{statusMessage}</div>}
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="floatingName">Name</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          <button className="btn btn-primary w-100 py-2" type="submit">Sign up</button>
          <p className="mt-5 mb-3 text-body-secondary">&copy; 2024 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default SignupPage;
