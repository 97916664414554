import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import HeadingsText from "../components/HeadingsText";
import HeadingsList from "../components/HeadingsList";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseScorePlot from "../components/ExerciseScorePlot";

const ReadingMatchingHeadings = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore( data._id, calculatedScore); }
  const handleTryAgain = () => { setHighlight(""); setScore(null); setResetTrigger((prev) => !prev); };
  const handleNextExercise = () => { refetchData(); handleTryAgain(); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      {/* Display the highlighted passage */}
      <HeadingsText passage={data.content.passage} highlight={highlight} showResults={score !== null} />

      {/* Headings list for selecting answers and score computation */}
      <HeadingsList
        passage={data.content.passage}
        headings={data.content.headings}
        onHighlightChange={setHighlight} // Pass the highlight handler
        onComplete={handleCompletion}
        resetTrigger={resetTrigger}
        showResults={score !== null}
      />

      {score !== null && (
        <>
          <FeedbackMessage score={score} />
          <ExerciseScorePlot data={data} />
          <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
        </>
      )}
    </div>
  );
};

export default ReadingMatchingHeadings;
