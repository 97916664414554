import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../styles/Breadcrumb.module.css'; // Import the CSS module

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const formatBreadcrumbName = (name, index) => {
    if (index === 0 && name.toLowerCase() === 'ielts') {
      return 'IELTS';
    }
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <nav aria-label="breadcrumb" className="my-3">
      <ol className="breadcrumb fs-2"> {/* Adjust the font size using Bootstrap classes */}
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return (
            <li
              key={to}
              className={`breadcrumb-item ${isLast ? styles.breadcrumbItemActive : ''}`}
              aria-current={isLast ? 'page' : undefined}
            >
              {isLast ? (
                formatBreadcrumbName(value, index)
              ) : (
                <Link
                  to={to}
                  className={styles.breadcrumbLink} // Apply the CSS module class
                >
                  {formatBreadcrumbName(value, index)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
