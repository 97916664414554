import React from "react";

const ExerciseNavigation = ({ onRetry, onNext }) => {
  return (
    <div className="mt-4 mb-4 text-center">
      <div className="mt-4">
        <>
          <button className="btn btn-primary btn-lg me-3" onClick={onRetry}>
            <i className="bi bi-arrow-repeat"></i> Try Again
          </button>
          <button className="btn btn-outline-primary btn-lg" onClick={onNext}>
            <i className="bi bi-arrow-right"></i> Next Exercise
          </button>
        </>
      </div>
    </div>
  );
};

export default ExerciseNavigation;
