import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

const PremiumPage = () => {
  return (
    <div className="container col-xxl-8 mt-5">
      <h1 className="text-center mb-4">Upgrade to Premium</h1>
      <p className="text-center mb-5">Unlock all features and maximise your learning experience with our premium membership.</p>
      <ul className="list-unstyled text-center fs-4">
        <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-infinity me-3"></i>
          Unlimited exercises in reading, writing, listening and speaking.
        </li>
        {/* <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-pencil-fill me-3" style={{ color: '#0d6efd' }}></i>
          Reading, writing, listening, speaking exercises
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-infinity me-3" style={{ color: '#0d6efd' }}></i>
          Unlimited daily exercises
        </li> */}
        <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-person-lines-fill me-3"></i>
          Detailed, personalised feedback with tips on how to improve.
        </li>
        {/* <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-graph-up-arrow me-3" style={{ color: '#0d6efd' }}></i>
          Detailed learning statistics
        </li>
        <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-pencil-square me-3" style={{ color: '#0d6efd' }}></i>
          IELTS, TOEFL practice exams
        </li> */}
        <li className="mb-4 d-flex align-items-center justify-content-center">
          <i className="bi bi-envelope-fill me-3"></i>
          Priority email support.
        </li>
      </ul>
      <div className="text-center mt-5">
        <Link to="/upgrade" className="btn btn-primary btn-lg">Upgrade Now</Link>
      </div>
    </div>
  );
};

export default PremiumPage;
