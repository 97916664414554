// hooks/useFetchData.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useFetchData = (api) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Encapsulate data fetching into a reusable function
  const fetchData = useCallback(async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      const response = await axios.get(api, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setError(null);
    } catch (err) {
      setError('Error fetching data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [api]); // This makes sure `fetchData` has access to the latest `api`

  // Fetch data initially when the component mounts
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Return the fetched data and the refetch function
  return { data, loading, error, refetchData: fetchData };
};
