import React from 'react';
import Feature from '../components/Feature'
import exercisesFeatureImage from '../assets/listening_thumbnail.png';
import feedbackFeatureImage from '../assets/writing_thumbnail.png';
import examFeatureImage from '../assets/speaking_thumbnail.png';
import languagesFeatureImage from '../assets/flags_thumbnail.png';

const Features = () => {
  return (
    <div className="container col-xxl-8 px-4 pt-5">
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h2 className="display-4 fw-normal text-body-emphasis">Features</h2>
            <p className="fs-5 text-body-secondary">
            Get personalised exercises and instant feedback, with realistic exam questions in 50+ languages.
            </p>
        </div>
        <div>
            <Feature
            heading="Personalised Exercises"
            description="We use advanced AI to create customized exercises in reading, writing, listening, and speaking specifically tailored to you. Focus on areas where you need the most improvement, maximizing the efficiency of your study time."
            image={exercisesFeatureImage}
            buttonText="Try an Exercise"
            textOnLeft={true}
            />
            <Feature
            heading="Instant Feedback"
            description="Receive immediate, detailed feedback on your performance in each exercise. Our advanced AI analyses your responses and provides personalised feedback, giving you concrete suggestions for how you can improve."
            image={feedbackFeatureImage}
            buttonText="Explore Feedback"
            textOnLeft={false}
            />
            <Feature
            heading="Realistic Questions"
            description="Solve questions that mirror those in popular exams like IELTS and TOEFL, including filling in the blanks, talking about a picture, writing essays, and answering questions about a conversation. Build confidence, ensuring you're ready for test day."
            image={examFeatureImage}
            buttonText="Try a Question"
            textOnLeft={true}
            />
            <Feature
              heading="50+ Languages"
              description="Master English, Spanish, French, German and 50+ other languages, and receive personalised feedback in your native language. Improve much faster, knowing the guidance and insights are delivered in the language you understand best!"
              image={languagesFeatureImage}
              buttonText="Explore Languages"
              textOnLeft={false}
            />
        </div>
    </div>
  );
};

export default Features;
