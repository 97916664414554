import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/DashboardPage.module.css'; // Import the CSS module
import SetLanguage from '../components/SetLanguage';
import ScorePlot from '../components/ScorePlot'; // Import the ScorePlot component
import ExerciseRecommendation from '../components/ExerciseRecommendation';

const DashboardPage = () => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();
  const [showThankYou, setShowThankYou] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const upgradedSessionId = queryParams.get('upgraded_session_id');
    if (upgradedSessionId) {
      axios.get(`/api/payments/session-status?session_id=${upgradedSessionId}`)
        .then((response) => {
          if (response.data.status === 'complete') {
            setShowThankYou(true);
            setCustomerEmail(response.data.customer_email);
          } else if (response.data.status === 'open') {
            setRedirectToCheckout(true);
          }
        });
    }
  }, [location.search]);

  if (loading) {
    return <div className="text-center mt-5"><div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div></div>;
  }

  if (redirectToCheckout) {
    return <Navigate to="/upgrade" />;
  }

  return (
    <div className="container mt-4 col-xxl-8">
      {showThankYou && (
        <div className="alert alert-success text-center" role="alert">
          Thank you for upgrading to Fluentious Premium! A confirmation email has been sent to {customerEmail}.
        </div>
      )}
      <h1 className="text-center">Dashboard</h1>
      <p className="text-center mb-4">Welcome back, {user.name}!</p>
      
      <SetLanguage />
      <ExerciseRecommendation />
      
      {/* Category Buttons */}
      <div className="row justify-content-center mt-4 mb-4">
        <div className="col-md-3">
          <Link to="/reading" className={`card text-center mb-4 text-decoration-none ${styles.card}`}>
            <div className="card-body d-flex flex-column align-items-center justify-content-center">
              <i className={`bi bi-book ${styles.cardIcon}`} style={{ fontSize: '4rem', color: '#0d6efd' }}></i>
              <h5 className="card-title">Reading</h5>
            </div>
          </Link>
          <ScorePlot exerciseCategory="reading" title="Reading Performance" />
        </div>
        
        <div className="col-md-3">
          <Link to="/writing" className={`card text-center mb-4 text-decoration-none ${styles.card}`}>
            <div className="card-body d-flex flex-column align-items-center justify-content-center">
              <i className={`bi bi-pencil ${styles.cardIcon}`} style={{ fontSize: '4rem', color: '#0d6efd' }}></i>
              <h5 className="card-title">Writing</h5>
            </div>
          </Link>
          <ScorePlot exerciseCategory="writing" title="Writing Performance" />
        </div>
        
        <div className="col-md-3">
          <Link to="/listening" className={`card text-center mb-4 text-decoration-none ${styles.card}`}>
            <div className="card-body d-flex flex-column align-items-center justify-content-center">
              <i className={`bi bi-headphones ${styles.cardIcon}`} style={{ fontSize: '4rem', color: '#0d6efd' }}></i>
              <h5 className="card-title">Listening</h5>
            </div>
          </Link>
          <ScorePlot exerciseCategory="listening" title="Listening Performance" />
        </div>
        
        <div className="col-md-3">
          <Link to="/speaking" className={`card text-center mb-4 text-decoration-none ${styles.card}`}>
            <div className="card-body d-flex flex-column align-items-center justify-content-center">
              <i className={`bi bi-mic ${styles.cardIcon}`} style={{ fontSize: '4rem', color: '#0d6efd' }}></i>
              <h5 className="card-title">Speaking</h5>
            </div>
          </Link>
          <ScorePlot exerciseCategory="speaking" title="Speaking Performance" />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
