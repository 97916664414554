import React from "react";
import Stars from "./Stars";
import SuggestionsList from "./SuggestionsList";

const FeedbackWriting = ({ feedback }) => {
  return (
    <div className="alert alert-warning mt-3" role="alert">
      <h2 className="text-center mb-3">Feedback</h2>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-graph-up me-2"></i>Overall:&nbsp;&nbsp;
          <Stars score={feedback.generalFeedbackAndOverallScore.score / 10} />
        </h3>
        <div>{feedback.generalFeedbackAndOverallScore.feedback}</div>
        <SuggestionsList suggestions={feedback.generalFeedbackAndOverallScore.suggestions} />
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-clipboard-check me-2"></i>Task Achievement:&nbsp;&nbsp;
          <Stars score={feedback.taskAchievementResponse.score / 10} />
        </h3>
        <div>{feedback.taskAchievementResponse.feedback}</div>
        <SuggestionsList suggestions={feedback.taskAchievementResponse.suggestions} />
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-link me-2"></i>Structure:&nbsp;&nbsp;
          <Stars score={feedback.coherenceAndCohesion.score / 10} />
        </h3>
        <div>{feedback.coherenceAndCohesion.feedback}</div>
        <SuggestionsList suggestions={feedback.coherenceAndCohesion.suggestions} />
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-book me-2"></i>Vocabulary:&nbsp;&nbsp;
          <Stars score={feedback.lexicalResource.score / 10} />
        </h3>
        <div>{feedback.lexicalResource.feedback}</div>
        <SuggestionsList suggestions={feedback.lexicalResource.suggestions} />
      </div>
      <div>
        <h3 className="fs-5">
          <i className="bi bi-gear me-2"></i>Grammar:&nbsp;&nbsp;
          <Stars score={feedback.grammaticalRangeAndAccuracy.score / 10} />
        </h3>
        <div>{feedback.grammaticalRangeAndAccuracy.feedback}</div>
        <SuggestionsList suggestions={feedback.grammaticalRangeAndAccuracy.suggestions} />
      </div>
    </div>
  );
};

export default FeedbackWriting;
