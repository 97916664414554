import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import styles from '../styles/ListeningPage.module.css';

const exercises = [
  { name: 'Multiple Choice', path: '/listening/multiple_choice', icon: 'bi-list-check' },
  { name: 'Fill In The Blanks', path: '/listening/fill_in_the_blanks', icon: 'bi-pencil-square' },
  { name: 'True / False / Not Given', path: '/listening/true_false_not_given', icon: 'bi-check-circle' },
  { name: 'Matching Speakers', path: '/listening/matching_speakers', icon: 'bi-people' }
];

const ListeningPage = () => {
  return (
    <div className="container mt-5 col-xxl-8">
      <Breadcrumb />
      <ul className="list-group">
        {exercises.map((exercise, index) => (
          <li key={index} className="list-group-item fs-5">
            <Link to={exercise.path} className={`${styles.listItemLink} d-flex align-items-center`}>
              <i className={`bi ${exercise.icon} me-2`}></i>
            {index + 1}. {exercise.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListeningPage;
