import React, { useState, useEffect } from "react";
import BlankInput from "./BlankInput";
import Explanations from "./Explanations";

const ClozePassage = ({ passage, answers, onComplete, resetTrigger }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [submittedAnswers, setSubmittedAnswers] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [reset, setReset] = useState(false); // Track the reset state

  // Reset state when resetTrigger changes
  useEffect(() => {
    setUserAnswers({});
    setSubmittedAnswers({});
    setIsCompleted(false);
    setReset(true); // Trigger resetting for the blanks and submit buttons
    setTimeout(() => setReset(false), 100); // Ensure reset state is cleared after a short delay
  }, [resetTrigger]);

  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value
    }));
  };

  const handleSubmitAnswer = (index) => {
    // Prevent empty submissions
    if (!userAnswers[index]) return;

    const correctAnswer = answers[index].correct_answer.toLowerCase();
    const userAnswer = userAnswers[index]?.toLowerCase() || "";

    // Update submitted answers
    setSubmittedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: {
        isCorrect: userAnswer === correctAnswer,
        explanation: answers[index].explanation,
        correctAnswer: answers[index].correct_answer,
        userAnswer: userAnswers[index]
      }
    }));

    // Check if all blanks have been submitted
    if (Object.keys(submittedAnswers).length + 1 === answers.length) {
      setIsCompleted(true);
      onComplete(
        (Object.values(submittedAnswers).filter((a) => a.isCorrect).length +
          (userAnswer === correctAnswer ? 1 : 0)) /
          answers.length
      );
    }
  };

  // Render the passage with blanks
  const renderPassageWithBlanks = () => {
    const parts = passage.split("_____");

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <BlankInput
            index={index}
            value={userAnswers[index] || ""}
            onChange={handleInputChange}
            onSubmit={handleSubmitAnswer}
            isSubmitted={submittedAnswers[index]}
            isCorrect={submittedAnswers[index]?.isCorrect}
            correctAnswer={submittedAnswers[index]?.correctAnswer}
            userAnswer={submittedAnswers[index]?.userAnswer}
            reset={reset} // Pass reset prop to clear inputs
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="alert alert-light mt-3" style={{ fontSize: "1rem" }}>
        <p style={{ textAlign: "left" }}>{renderPassageWithBlanks()}</p>
      </div>

      {/* Pass explanations to the Explanations component */}
      {Object.keys(submittedAnswers).length > 0 && (
        <Explanations explanations={submittedAnswers} />
      )}
    </>
  );
};

export default ClozePassage;
