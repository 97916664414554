import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {
  const { user } = useContext(AuthContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/api/user/subscription-status', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const handleCancelSubscription = async () => {
    const token = localStorage.getItem('token');
    try {
      setIsLoading(true);
      await axios.post('/api/user/cancel-subscription', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubscriptionStatus('cancelled');
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/premium');
  };

  return (
    <div className="container mt-5 text-center">
      <h1>Settings</h1>
      <div className="mb-3 mt-4">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {subscriptionStatus === 'active' ? (
              <div>
                <img src={premiumImage} alt="Premium Subscription" style={{ height: '25px' }} />
                <button
                  type="button"
                  className="btn btn-danger mx-3"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </button>
              </div>
            ) : (
              <div>
                <img src={basicImage} alt="Basic Subscription" style={{ height: '25px' }} />
                <button
                  type="button"
                  className="btn btn-primary mx-3"
                  onClick={handleSubscribe}
                >
                  Upgrade
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
