import highlightCorrections from "../utils/highlightCorrections";

const CorrectedResponse = ({text}) => {
  return (
    <div className="alert alert-light mt-3" role="alert">
      <h2 className="text-center mb-3">Your Response</h2>
      <div>{highlightCorrections(text)}</div>
    </div>
  );
};

export default CorrectedResponse;
