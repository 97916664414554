import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { updateProficiencyLevel, updateKnownLanguage, updateTargetLanguage } from '../services/userService';

const SetLanguage = ({ disabled = false }) => {
  const { user, setUser } = useContext(AuthContext);
  const [proficiencyLevel, setProficiencyLevel] = useState(user?.proficiencyLevel || 'A1');
  const [knownLanguage, setKnownLanguage] = useState(user?.knownLanguage || 'EN');  // Default to English
  const [targetLanguage, setTargetLanguage] = useState(user?.targetLanguage || 'EN');  // Default to English
  const [loading, setLoading] = useState(false);

  const handleLevelChange = async (e) => {
    const newLevel = e.target.value;
    setProficiencyLevel(newLevel);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const updatedUser = await updateProficiencyLevel(newLevel, token);
      setUser((prevUser) => ({ ...prevUser, proficiencyLevel: updatedUser.proficiencyLevel }));
    } catch (error) {
      console.error("Error updating proficiency level:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = async (e, type) => {
    const newLanguage = e.target.value;
    setLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      
      if (type === 'known') {
        setKnownLanguage(newLanguage);
        await updateKnownLanguage(newLanguage, token);
      } else {
        setTargetLanguage(newLanguage);
        await updateTargetLanguage(newLanguage, token);
      }

      setUser((prevUser) => ({
        ...prevUser,
        knownLanguage: type === 'known' ? newLanguage : prevUser.knownLanguage,
        targetLanguage: type === 'target' ? newLanguage : prevUser.targetLanguage,
      }));
      
    } catch (error) {
      console.error("Error updating language:", error);
    } finally {
      setLoading(false);
    }
  };

  const languages = [
    { code: "AF", name: "Afrikaans" },
    { code: "AR", name: "Arabic" },
    { code: "HY", name: "Armenian" },
    { code: "AZ", name: "Azerbaijani" },
    { code: "BE", name: "Belarusian" },
    { code: "BS", name: "Bosnian" },
    { code: "BG", name: "Bulgarian" },
    { code: "CA", name: "Catalan" },
    { code: "ZH", name: "Chinese" },
    { code: "HR", name: "Croatian" },
    { code: "CS", name: "Czech" },
    { code: "DA", name: "Danish" },
    { code: "NL", name: "Dutch" },
    { code: "EN", name: "English" },
    { code: "ET", name: "Estonian" },
    { code: "FI", name: "Finnish" },
    { code: "FR", name: "French" },
    { code: "GL", name: "Galician" },
    { code: "DE", name: "German" },
    { code: "EL", name: "Greek" },
    { code: "HE", name: "Hebrew" },
    { code: "HI", name: "Hindi" },
    { code: "HU", name: "Hungarian" },
    { code: "IS", name: "Icelandic" },
    { code: "ID", name: "Indonesian" },
    { code: "IT", name: "Italian" },
    { code: "JA", name: "Japanese" },
    { code: "KN", name: "Kannada" },
    { code: "KK", name: "Kazakh" },
    { code: "KO", name: "Korean" },
    { code: "LV", name: "Latvian" },
    { code: "LT", name: "Lithuanian" },
    { code: "MK", name: "Macedonian" },
    { code: "MS", name: "Malay" },
    { code: "MR", name: "Marathi" },
    { code: "MI", name: "Maori" },
    { code: "NE", name: "Nepali" },
    { code: "NO", name: "Norwegian" },
    { code: "FA", name: "Persian" },
    { code: "PL", name: "Polish" },
    { code: "PT", name: "Portuguese" },
    { code: "RO", name: "Romanian" },
    { code: "RU", name: "Russian" },
    { code: "SR", name: "Serbian" },
    { code: "SK", name: "Slovak" },
    { code: "SL", name: "Slovenian" },
    { code: "ES", name: "Spanish" },
    { code: "SW", name: "Swahili" },
    { code: "SV", name: "Swedish" },
    { code: "TL", name: "Tagalog" },
    { code: "TA", name: "Tamil" },
    { code: "TH", name: "Thai" },
    { code: "TR", name: "Turkish" },
    { code: "UK", name: "Ukrainian" },
    { code: "UR", name: "Urdu" },
    { code: "VI", name: "Vietnamese" },
    { code: "CY", name: "Welsh" },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}>
      <div className="alert alert-light" role="alert" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '0px', paddingBottom: '5px' }}>
        <div className="form-group" style={{ marginRight: '5px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="knownLanguage" style={{ whiteSpace: 'nowrap' }}>I speak</label>
          <select
            id="knownLanguage"
            value={knownLanguage}
            onChange={(e) => handleLanguageChange(e, 'known')}
            className="form-select"
            style={{ marginLeft: '5px', width: '100%' }}
            disabled={loading || disabled}
          >
            {languages.map(lang => (
              <option key={lang.code} value={lang.code}>{lang.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group" style={{ marginRight: '5px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="targetLanguage" style={{ whiteSpace: 'nowrap' }}>and I want to learn</label>
          <select
            id="targetLanguage"
            value={targetLanguage}
            onChange={(e) => handleLanguageChange(e, 'target')}
            className="form-select"
            style={{ marginLeft: '5px', width: '100%' }}
            disabled={loading || disabled}
          >
            {languages.map(lang => (
              <option key={lang.code} value={lang.code}>{lang.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group" style={{ marginRight: '10px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="proficiencyLevel" style={{ whiteSpace: 'nowrap' }}>at the level</label>
          <select
            id="proficiencyLevel"
            value={proficiencyLevel}
            onChange={handleLevelChange}
            className="form-select"
            style={{ marginLeft: '5px', width: '100%' }}
            disabled={loading || disabled}
          >
            <option value="A1">Beginner (A1)</option>
            <option value="A2">Elementary (A2)</option>
            <option value="B1">Intermediate (B1)</option>
            <option value="B2">Upper Intermediate (B2)</option>
            <option value="C1">Advanced (C1)</option>
            <option value="C2">Proficient (C2)</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default SetLanguage;
