import Stars from "./Stars";

const getFeedbackMessage = (score) => {
  if (score === 1) {
    return "Excellent Job!";
  } else if (score >= 0.8) {
    return "Great Job!";
  } else if (score >= 0.6) {
    return "Good Effort!";
  } else {
    return "Keep Practicing!";
  }
};

const FeedbackMessage = ({ score }) => {
  return (
    <div className='text-center'>
      <h2>{getFeedbackMessage(score)}</h2>
      <div style={{ fontSize: "2rem", color: "#ffc107" }}>
        <Stars score={score} />
      </div>
    </div>
  );
};

export default FeedbackMessage;
