import React from 'react';

const Table = ({ tableData }) => {
  const { title, columns, rows } = tableData;

  return (
    <div>
      <div className="text-center fs-4 mb-2" style={{ color: 'black' }}>{title}</div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
