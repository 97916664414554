import React from "react";

const HighlightedPassage = ({ passage, highlight }) => {
  const highlightText = (text, highlight) => {
    if (!highlight) return text;
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <mark key={index}>{part}</mark> : part
    );
  };

  return (
    <div className="main-text mb-4 alert alert-light" style={{ textAlign: "justify" }}>
      {highlightText(passage, highlight)}
    </div>
  );
};

export default HighlightedPassage;
