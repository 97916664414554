import React from "react";

const HeadingsText = ({ passage, highlight, showResults }) => {
  return (
    <div className="alert alert-light">
      {passage.map((section, index) => {
        // If the highlight matches, wrap the relevant part in <mark>, otherwise render plain text
        const highlightedText = showResults && highlight ? section.text.replace(
          highlight,
          `<mark>${highlight}</mark>`
        ) : section.text;

        return (
          <p key={index} style={{ marginBottom: index === passage.length - 1 ? "0" : "1rem" }}>
            <strong>{String.fromCharCode(65 + index)}.</strong>{" "}
            {/* Use dangerouslySetInnerHTML to safely render the marked text */}
            <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
          </p>
        );
      })}
    </div>
  );
};

export default HeadingsText;
