import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import styles from '../styles/WritingPage.module.css';

const exercises = [
  { name: 'Essay', path: '/writing/essay', icon: 'bi-pencil-square' },
  { name: 'Letter', path: '/writing/letter', icon: 'bi-envelope-paper' },
  { name: 'Table Description', path: '/writing/table_description', icon: 'bi-table' },
  { name: 'Text Summarisation', path: '/writing/text_summarisation', icon: 'bi-funnel' }
];

const ReadingPage = () => {
  return (
    <div className="container mt-5 col-xxl-8">
      <Breadcrumb />
      <ul className="list-group">
        {exercises.map((exercise, index) => (
          <li key={index} className="list-group-item fs-5">
            <Link to={exercise.path} className={`${styles.listItemLink} d-flex align-items-center`}>
              <i className={`bi ${exercise.icon} me-2`}></i>
            {index + 1}. {exercise.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReadingPage;
