import React, { useState, useEffect } from "react";
import QuestionItem from "./QuestionItem";

const areAllQuestionsAnswered = (userAnswers, questions) => {
  return questions.every((_, index) => userAnswers[index] && userAnswers[index].userAnswer);
};

const calculateScore = (userAnswers, questions) => {
  return Object.values(userAnswers).filter(answer => answer.isCorrect).length / questions.length;
};

const QuestionsList = ({ questions, onHighlightChange, onComplete, resetTrigger }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [lastAnsweredIndex, setLastAnsweredIndex] = useState(null);

  useEffect(() => { 
    setUserAnswers({}); setLastAnsweredIndex(null); onHighlightChange("");
  }, [resetTrigger, onHighlightChange]);

  const handleOptionChange = (questionIndex, answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionIndex]: answer };
      
      setLastAnsweredIndex(questionIndex);
      onHighlightChange(questions[questionIndex]?.reference || "");

      if (areAllQuestionsAnswered(newAnswers, questions)) {
        const score = calculateScore(newAnswers, questions);
        onComplete(score);
      }

      return newAnswers;
    });
  };

  const handleHoverChange = (questionIndex) => {
    if (userAnswers[questionIndex]) { onHighlightChange(questions[questionIndex]?.reference || ""); }
  };

  const handleMouseLeave = () => {
    if (lastAnsweredIndex !== null) { onHighlightChange(questions[lastAnsweredIndex]?.reference || ""); } 
    else { onHighlightChange(""); }
  };

  return (
    <div>
      {questions.map((question, index) => (
        <QuestionItem
          key={index}
          questionData={question}
          questionIndex={index}
          userAnswer={userAnswers[index]}
          onOptionChange={handleOptionChange}
          onHover={() => handleHoverChange(index)}
          onLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default QuestionsList;
