import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import QuestionsList from "../components/QuestionsList";
import HighlightedPassage from "../components/HighlightedPassage";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseScorePlot from "../components/ExerciseScorePlot";

const ReadingMultipleChoice = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore(data._id, calculatedScore); };
  const handleTryAgain = () => { setHighlight(""); setScore(null); setResetTrigger((prev) => !prev); };
  const handleNextExercise = () => { refetchData(); handleTryAgain(); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <HighlightedPassage passage={data.content.passage} highlight={highlight} />
      <QuestionsList
        questions={data.content.questions}
        onHighlightChange={setHighlight}
        onComplete={handleCompletion}
        resetTrigger={resetTrigger}
      />
      {score !== null &&
        <>
          <FeedbackMessage score={score} />
          <ExerciseScorePlot data={data} />
          <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
        </>
      }
    </div>
  );
};

export default ReadingMultipleChoice;
