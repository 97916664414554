import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/logo.png';
import styles from '../styles/Header.module.css';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const handleLogout = () => {
    logout(() => (window.location.href = '/'));
  };

  const menuItems = user
    ? [
        { to: '/dashboard', label: 'Home', icon: 'bi-house-door' },
        { to: '/reading', label: 'Read', icon: 'bi-book' },
        { to: '/writing', label: 'Write', icon: 'bi-pencil' },
        { to: '/listening', label: 'Listen', icon: 'bi-headphones' },
        { to: '/speaking', label: 'Speak', icon: 'bi-mic' },
      ]
    : [
        { to: '/', label: 'Home', icon: 'bi-house-door' },
        { to: '/#features', label: 'Features', icon: 'bi-star' },
        { to: '/#pricing', label: 'Pricing', icon: 'bi-tag' },
        { to: '/#faq', label: 'FAQ', icon: 'bi-question-circle' },
      ];

  const profileItems = [
    { to: '/profile', label: 'Profile', icon: 'bi-person' },
    { to: '/settings', label: 'Settings', icon: 'bi-gear' },
    { onClick: handleLogout, label: 'Sign out', icon: 'bi-box-arrow-right', isButton: true },
  ];

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary p-3" style={{ borderBottom: '1px solid rgb(222, 226, 230)' }}>
      <div className="container-fluid px-3">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" width="100" className="d-inline-block align-baseline mx-1" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {menuItems.map(({ to, label, icon }) => (
              <li className="nav-item" key={to}>
                <Link className={`nav-link ${styles.navLink}`} to={to}>
                  <i className={`bi ${icon} me-1`}></i> {label}
                </Link>
              </li>
            ))}
            {user && (
              <>
                <li className={`nav-item ${styles.mobileOnly}`}>
                  <button
                    className={`nav-link ${styles.navLink}`}
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                  >
                    <i className="bi bi-person-circle me-1" style={{ fontSize: '24px' }}></i> <i className="bi bi-chevron-down"></i>
                  </button>
                </li>
                {showProfileMenu && (
                  <>
                    {profileItems.map(({ to, label, icon, onClick, isButton }, index) => (
                      <li className={`nav-item ${styles.mobileOnly} ${styles.profileItem}`} key={index}>
                        {isButton ? (
                          <button className={`nav-link ${styles.navLink}`} onClick={onClick}>
                            <i className={`bi ${icon} me-1`}></i> {label}
                          </button>
                        ) : (
                          <Link className={`nav-link ${styles.navLink}`} to={to}>
                            <i className={`bi ${icon} me-1`}></i> {label}
                          </Link>
                        )}
                      </li>
                    ))}
                  </>
                )}
              </>
            )}
          </ul>
          {user && (
            <div className="dropdown d-none d-lg-block">
              <a
                href="#"
                className={`nav-link dropdown-toggle ${styles.navLink}`}
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-person-circle" style={{ fontSize: '24px' }}></i>
              </a>
              <ul className={`dropdown-menu dropdown-menu-end ${styles.dropdownMenu}`} aria-labelledby="userDropdown">
                {profileItems.map(({ to, label, icon, onClick, isButton }, index) => (
                  <li key={index}>
                    {isButton ? (
                      <button className="dropdown-item fs-6" onClick={onClick}>
                        <i className={`bi ${icon} me-1`}></i> {label}
                      </button>
                    ) : (
                      <Link className="dropdown-item fs-6" to={to}>
                        <i className={`bi ${icon} me-1`}></i> {label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!user && (
            <div className="d-flex">
              <Link to="/login" className={`btn btn-outline-primary me-2 ${styles.btnMobile}`}>
                <i className="bi bi-box-arrow-in-right me-1"></i> Login
              </Link>
              <Link to="/signup" className={`btn btn-primary ${styles.btnMobile}`}>
                <i className="bi bi-person-plus me-1"></i> Sign up
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
