import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Feature = ({ heading, description, image, buttonText, textOnLeft }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup');
  };

  return (
    <div className={`row align-items-center g-5 py-3 mb-3 ${textOnLeft ? '' : 'flex-lg-row-reverse'}`}>
      <div className="col-12 col-md-8 col-lg-6 mb-lg-0">
        <img 
          src={image} 
          className="d-block mx-auto img-fluid" 
          alt={heading} 
          style={{ maxWidth: '100%', height: 'auto' }} 
          loading="lazy" 
        />
      </div>
      <div className="col-12 col-lg-6 text-center text-lg-start mt-4">
        <h1 className="display-5 text-body-emphasis lh-1 mb-3">
          {heading}
        </h1>
        <p className="lead">
          {description}
        </p>
        <div className="d-grid gap-2 d-md-flex justify-content-md-start justify-content-center">
          <button 
            type="button" 
            className="btn btn-primary btn-lg px-4" 
            onClick={handleButtonClick}
            style={{ width: '100%', maxWidth: '300px' }} // Full width on mobile, constrained on desktop
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feature;
