import { useState } from 'react';
import axios from 'axios';

export const useSubmitScore = () => {
  const [isSubmittingScore, setIsSubmittingScore] = useState(false);
  const [score, setScore] = useState(null);

  const submitScore = async (exerciseId, score) => {
    const token = localStorage.getItem('token');
    setIsSubmittingScore(true);
    try {
      await axios.post(
        `/api/exercises/save-score`, 
        { exerciseId, score }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setScore(score); // Store the submitted score in the state
    } catch (error) {
      console.error('Error submitting score:', error);
      throw error;
    } finally {
      setIsSubmittingScore(false); // Done submitting score
    }
  };

  return { isSubmittingScore, submitScore, score, setScore }; // Return score state as well
};
