import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitExercise } from "../hooks/useSubmitExercise";
import WritingArea from "../components/WritingArea";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackWriting from "../components/FeedbackWriting.js";
import FeedbackMessage from "../components/FeedbackMessage";
import ExerciseNavigation from "../components/ExerciseNavigation";
import ExerciseScorePlot from "../components/ExerciseScorePlot.js";

const WritingEssay = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { result, setResult, isSubmitting, submitExercise } = useSubmitExercise();
  const [essayText, setEssayText] = useState('');

  const handleEssayChange = (text) => { setEssayText(text); };

  const handleSubmit = () => {
    submitExercise(api, {
      'exerciseId': data._id,
      'prompt': data.content.prompt,
      'instructions': data.content.instructions,
      'passage': data.content.passage,
      'response': essayText
    })
    .then((response) => { setEssayText(response.correctedResponse); });
  };

  const handleTryAgain = () => { setEssayText(''); setResult(null); };
  const handleNextExercise = () => { refetchData(); setEssayText(''); setResult(null); };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (
    <div>
      <p className="m-3">{data.instructions}</p>
      <p className="m-3">{data.prompt}</p>

      {data.content.passage && 
        <div className="main-text mb-4 alert alert-light" style={{ textAlign: 'justify' }}>
          {data.content.passage}
        </div>
      }

      <WritingArea value={essayText} onChange={handleEssayChange} />

      <div className="d-flex align-items-center">
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>Submit</button>
        {isSubmitting && <LoadingSpinner inline={true} />}
      </div>

      {result && <>
        <FeedbackWriting feedback={result} />
        <FeedbackMessage score={result.generalFeedbackAndOverallScore.score / 10} />
        <ExerciseScorePlot data={data} />
        <ExerciseNavigation onRetry={handleTryAgain} onNext={handleNextExercise} />
      </>}
    </div>
  );
};

export default WritingEssay;
