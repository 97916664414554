import React, { useState, useEffect, useRef } from "react";

const BlankInput = ({
  index,
  value,
  onChange,
  onSubmit,
  isSubmitted,
  isCorrect,
  correctAnswer,
  userAnswer,
  reset
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [hovered, setHovered] = useState(false); // Track hover state for smooth transition
  const inputRef = useRef(null); // Reference to dynamically calculate width

  const MIN_WIDTH = 150; // Set a minimum starting width for the blank
  const BUTTON_WIDTH = 25; // Width for the submit button inside the blank
  const BUTTON_PADDING = 30; // Extra padding for submit button space

  // Reset input value and hover state when reset is triggered
  useEffect(() => {
    setInputValue(""); // Clear the input value on reset
    setHovered(false); // Reset the hover state
  }, [reset]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue) {
      onSubmit(index);
    }
  };

  // Function to dynamically calculate the width based on text content
  const calculateWidth = (text) => {
    const textLength = text.length * 10 + BUTTON_PADDING; // Adjust calculation for text length and button padding
    return textLength < MIN_WIDTH ? `${MIN_WIDTH}px` : `${textLength}px`;
  };

  // Adjust the blank width to fit the word
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = calculateWidth(inputValue); // Adjust based on input
    }
  }, [inputValue]);

  return (
    <span>
      {!isSubmitted ? (
        <span style={{ whiteSpace: "nowrap" }}>
          {/* Bold number before blank */}
          <strong>({index + 1})</strong>{" "}
          <span
            style={{
              display: "inline-block",
              position: "relative", // Make the blank container relative
              textAlign: "center",
              marginRight: "5px",
              borderBottom: "1px solid black",
              paddingRight: `${BUTTON_PADDING}px`, // Ensure space for submit button inside the blank
              width: calculateWidth(inputValue), // Dynamically calculate width of the blank
              transition: "width 0.2s ease-in-out" // Smooth transition for blank width
            }}
          >
            {/* Input field */}
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                onChange(index, e.target.value);
              }}
              onKeyPress={handleKeyPress}
              style={{
                border: "none",
                outline: "none",
                background: "transparent",
                width: "100%",
                textAlign: "center"
              }}
            />
            {/* Submit button inside the blank */}
            <i
              className={`bi ${hovered ? "bi-arrow-right-circle-fill" : "bi-arrow-right-circle"}`}
              onClick={() => inputValue && onSubmit(index)} // Prevent empty submissions
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "5px", // Position button inside the right end of the blank
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "1.2rem",
                color: "#007bff",
                transition: "color 0.25s ease, transform 0.25s ease", // Smooth transition for hover
              }}
            ></i>
          </span>
        </span>
      ) : (
        <span style={{ whiteSpace: "nowrap" }}>
          {/* Bold number before blank */}
          <strong>({index + 1})</strong>{" "}
          <span
            style={{
              display: "inline-block",
              borderBottom: "1px solid black",
              textAlign: "center",
              paddingRight: `${BUTTON_PADDING}px`, // Space for the button (even after submission)
              marginRight: "5px",
              position: "relative",
              transition: "width 0.2s ease-in-out",
              width: calculateWidth(
                isCorrect
                  ? userAnswer
                  : `${userAnswer} ${correctAnswer}` // Fit both incorrect and correct answers
              )
            }}
          >
            {/* If correct, display in green; if incorrect, display both incorrect and correct words */}
            <span
              style={{
                color: isCorrect ? "#198754" : "#dc3545",
                textDecoration: isCorrect ? "none" : "line-through"
              }}
            >
              {userAnswer}
            </span>
            {!isCorrect && (
              <span style={{ color: "#198754", marginLeft: "5px" }}>
                {correctAnswer}
              </span>
            )}
          </span>
        </span>
      )}
    </span>
  );
};

export default BlankInput;
