import { useState } from 'react';
import axios from 'axios';

export const useSubmitExercise = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  const submitExercise = async (api, formData, contentType='application/json') => {
    const token = localStorage.getItem('token');
    setIsSubmitting(true);
    try {
      const response = await axios.post(api, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': contentType,
        },
      });
      setResult(response.data);
      setIsSubmitting(false);
      return response.data;
    } catch (error) {
      console.error('Error submitting exercise:', error);
    }
  };

  return { isSubmitting, submitExercise, result, setResult };
};
