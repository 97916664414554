// ProfilePage.js
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';

const ProfilePage = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="container mt-5 text-center">
      <h1>Profile</h1>
      <div>
        <p className="mb-0"><i className="bi bi-person-circle" style={{ fontSize: '100px' }}></i></p>
        <p className="fs-4">{user.name}</p>
        <p className="fs-5">{user.email}</p>
        {user.role === 'premium' ? (
          <p><img src={premiumImage} alt="Premium Subscription" style={{ height: '20px' }} /></p>
        ) : (
          <p><img src={basicImage} alt="Basic Subscription" style={{ height: '20px' }} /></p>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
