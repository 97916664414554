import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'How do personalised exercises work?',
      answer: 'We create customised exercises for you in reading, writing, listening, and speaking, that are personalised to your current level. This ensures you focus on areas where you need the most improvement, saving your time.',
    },
    {
      question: 'What kind of feedback do I get?',
      answer: 'We provide immediate, detailed feedback on your performance in each exercise with concrete suggestions on how you can improve. No more waiting for days until you get your corrected essay back!',
    },
    {
      question: 'What exercises does your platform include?',
      answer: 'Our platform includes many common exercises in reading, writing, listening and speaking, that mirror those in popular exams like IELTS and TOEFL. We are adding new exercises every day so you\'ll never run out!',
    },
    {
      question: 'What languages does your platform support?',
      answer: 'We support Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.',
    },
    {
      question: 'Is there support available if I have questions?',
      answer: 'Yes, we offer priority email support for Premium and Institution plans. If you have any questions please contact us at contact@fluentious.com and we\'ll be happy to help!',
    },
    {
      question: 'What does the premium subscription include?',
      answer: 'The premium subscription includes unlimited exercises, detailed personalised feedback, and priority email support.',
    },
    {
      question: 'How can I subscribe or unsubscribe to premium?',
      answer: 'You can subscribe and unsubscribe easily from the settings page once you log in. Please let us know if you\'re having any trouble with your subscription. ',
    },
    {
      question: 'I am an institution and have custom requirements.',
      answer: 'Please contact us at sales@fluentious.com and we\'ll be happy to discuss a custom plan with you!',
    },
  ];

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">Frequently Asked Questions</h2>
        <p className="fs-5 text-body-secondary">
          Here are the answers to some our most commonly asked questions.
        </p>
      </div>
      <div className="accordion" id="faqAccordion">
        {faqs.map((faq, index) => (
          <div className="accordion-item" key={index}>
            <h3 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
                // style={{ fontWeight: 'bold' }}
              >
                {faq.question}
              </button>
            </h3>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
