import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
    <div className="container col-xxl-8 px-4 pt-5">
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h2 className="display-4 fw-normal text-body-emphasis">Pricing</h2>
            <p className="fs-5 text-body-secondary">
            Choose the best plan for you. Both plans come with personalised exercises and feedback.
            </p>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 text-center mb-3">
          {/* Basic Plan */}
          <div className="col d-flex">
            <div className="card w-100 rounded-3 shadow-sm h-100 d-flex flex-column">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Basic</h4>
              </div>
              <div className="card-body d-flex flex-column">
                {/* Price Section */}
                <div className="mb-4 mt-3 d-flex align-items-center justify-content-center" style={{ height: '50px' }}>
                  <h1 className="card-title pricing-card-title fw-normal">Free</h1>
                </div>
                {/* Features Section */}
                <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '120px' }}>
                  <li>Limited exercises.</li>
                  <li>Basic feedback.</li>
                  <li>50+ languages.</li>
                </ul>
                {/* CTA Section */}
                <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                  <Link to="/signup" className="w-100 btn btn-lg btn-outline-primary">Get Started for Free</Link>
                </div>
              </div>
            </div>
          </div>
          {/* Premium Plan */}
          <div className="col d-flex">
            <div className="card w-100 rounded-3 shadow-sm border-primary h-100 d-flex flex-column">
              <div className="card-header py-3 text-bg-primary border-primary">
                <h4 className="my-0 fw-normal">Premium</h4>
              </div>
              <div className="card-body d-flex flex-column">
                {/* Price Section */}
                <div className="mb-4 mt-3 d-flex flex-column align-items-center justify-content-center" style={{ height: '50px' }}>
                  <h1 className="card-title pricing-card-title fw-normal">
                    <span style={{ textDecoration: 'line-through', color: '#999' }}>$19.90</span>&nbsp;$11.90
                    <small className="text-body-secondary fw-light">/mo</small>
                  </h1>
                  <p className="text-danger mb-0">40% off for a limited time!</p>
                </div>
                {/* Features Section */}
                <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '120px' }}>
                  <li>Unlimited exercises.<span style={{color: '#999999'}}>*</span></li>
                  <li>Detailed feedback.</li>
                  <li>Stats & analytics.</li>
                  <li>50+ languages.</li>
                </ul>
                {/* CTA Section */}
                <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                  <Link to="/signup" className="w-100 btn btn-lg btn-primary">Subscribe</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{color: '#999999'}}>* Subject to fair usage policy.</div>
    </div>
  );
};

export default Pricing;
